<template>
   <article class="subscription main__subscription">
      <div class="subscription__container container">
         <h2 class="subscription__section-title section-title">
            Подписывайтесь на&nbsp;наши новости
         </h2>
         <p class="subscription__subtitle">и&nbsp;получите промокод на&nbsp;скидку</p>

         <form
            @submit.prevent="submitForm"
            class="subscription__subscription-form subscription-form form"
            action="#"
            novalidate
         >
            <app-field
               :settings="fieldData"
               :isFormSubmission="isFormSubmission"
               :isDisplayedError="isDisplayedError"
               @result-checking="saveResultChecking"
               @form-reset="resetForm"
               v-model:field="emailField"
            />

            <button ref="formBtn" class="subscription-form__btn btn">Отправить</button>
         </form>

         <social-networks class="subscription__social-networks">
            <template #title>Следите за нами</template>
         </social-networks>
      </div>
   </article>
</template>

<script>
   import AppField from '@/components/templates/FormFields/AppField';
   import SocialNetworks from '@/components/templates/SocialNetworks';

   export default {
      name: 'AppSubscription',

      components: {
         AppField,
         SocialNetworks
      },

      inject: ['openModal'],

      data() {
         return {
            emailField: '',
            isFormSubmission: false,
            isDisplayedError: false,
            fieldData: {
               name: 'Email',
               type: 'email',
               validationType: 'Email',
               placeholder: 'Введите Ваш e-mail'
            },
            fieldsValidationResults: [],

            modalSettings: {
               name: 'ModalNotification',
               iconName: '',
               message: '',
               isActive: true
            }
         };
      },

      methods: {
         saveResultChecking(value) {
            const field = this.fieldsValidationResults.find(
               (item) => item.name === value.name && item.type === value.type
            );

            if (field) {
               field.isValid = value.isValid;
            } else {
               this.fieldsValidationResults.push(value);
            }
         },

         resetForm(value) {
            this.isFormSubmission = value;
            this.$refs.formBtn.blur();
         },

         submitForm() {
            if (this.isValidFieldValue) {
               this.isFormSubmission = true;
               this.isDisplayedError = false;

               this.saveDataToLocalStorage();

               this.openModal(this.modalSettings);
            } else {
               this.isDisplayedError = true;
            }
         },

         saveDataToLocalStorage() {
            const isSubscribed = localStorage.getItem('subscription') === this.emailField;

            this.modalSettings.message = isSubscribed
               ? 'Подписка уже оформлена!'
               : 'Подписка на новости оформлена!';

            this.modalSettings.iconName = isSubscribed ? 'warning' : 'ready';

            localStorage.setItem('subscription', this.emailField);
         }
      },

      computed: {
         isValidFieldValue() {
            return (
               Boolean(this.fieldsValidationResults.length) &&
               this.fieldsValidationResults.every((item) => item.isValid)
            );
         }
      }
   };
</script>
